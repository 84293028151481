import React from "react";

const ProfessionalInvoice = ({ data }) => {
  const {
    toCompany,
    myName,
    myEmail,
    myAddress,
    invoiceNumber,
    date,
    paymentMethod,
    paymentDetails,
    currency,
    tasks,
    totalDue,
    themeColor,
    themeFont
  } = data;

  return (
    <div style={{fontFamily: themeFont}} className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200">
      {/* Header Section */}
      <div style={{backgroundColor: themeColor}} className="text-white p-8">
        <div className="flex justify-between">
          <div>
            <h1 className="text-2xl font-bold">{toCompany}</h1>
            <p className="mt-2 text-sm">{myName}</p>
            <p className="text-sm">{myEmail}</p>
            <p className="text-sm">{myAddress}</p>
          </div>
          <div className="text-right">
            <h2 className="text-xl font-bold">Invoice</h2>
            <p className="text-sm">#{invoiceNumber}</p>
            <p className="text-sm">{date}</p>
          </div>
        </div>
      </div>

      {/* Payment Details Section */}
      <div className="p-6 bg-gray-100">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-gray-600 text-lg font-semibold mb-2">
              Payment Method
            </h3>
            <p className="text-sm">{paymentMethod}</p>
          </div>
          <div>
            <h3 className="text-gray-600 text-lg font-semibold mb-2">
              Payment Details
            </h3>
            <p className="text-sm">{paymentDetails}</p>
          </div>
        </div>
      </div>

      {/* Task List */}
      <div className="p-6">
        <h3 className="text-gray-700 text-lg font-semibold mb-4">Tasks</h3>
        <div className="overflow-hidden border border-gray-200 rounded-md">
          <table className="w-full table-auto">
            <thead className="bg-gray-200">
              <tr>
                <th className="text-left px-4 py-2 text-sm text-gray-600">
                  Description
                </th>
                <th className="text-right px-4 py-2 text-sm text-gray-600">
                  Hours
                </th>
                <th className="text-right px-4 py-2 text-sm text-gray-600">
                  Rate
                </th>
                <th className="text-right px-4 py-2 text-sm text-gray-600">
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              {tasks.length > 0 ? (
                tasks.map((task, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    <td className="px-4 py-2 text-sm text-gray-700">
                      {task.itemName || "No Name"}
                    </td>
                    <td className="px-4 py-2 text-right text-sm text-gray-700">
                      {task.itemHours || 0}
                    </td>
                    <td className="px-4 py-2 text-right text-sm text-gray-700">
                      {currency}
                      {task.itemRate || 0}
                    </td>
                    <td className="px-4 py-2 text-right text-sm text-gray-700">
                      {currency}
                      {task.itemSubtotal || 0}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    className="px-4 py-6 text-center text-gray-500"
                  >
                    No items added yet
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Total Section */}
      <div className="p-6 bg-gray-50 border-t border-gray-200">
        <div className="flex justify-between items-center">
          <h3 className="text-gray-700 text-lg font-semibold">Total Due</h3>
          <p className="text-xl font-bold text-gray-800">
            {currency}
            {totalDue || 0}
          </p>
        </div>
      </div>

      {/* Footer Section */}
      <div className="p-6 text-center text-sm text-gray-500 border-t border-gray-200">
        Thank you for your business! If you have any questions, feel free to
        contact us.
      </div>
    </div>
  );
};

export default ProfessionalInvoice;
