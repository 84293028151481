import React from "react";

const SlickInvoiceTemplate = ({ data }) => {
  const {
    toCompany,
    myName,
    myEmail,
    myAddress,
    invoiceNumber,
    date,
    paymentMethod,
    paymentDetails,
    currency,
    tasks,
    rate,
    totalDue,
    themeColor,
    themeFont
  } = data;

  return (
    <div style={{fontFamily: themeFont}} className="max-w-4xl mx-auto bg-white shadow-md rounded-md overflow-hidden font-sans">
      {/* Header */}
      <div style={{ backgroundColor: themeColor }} className="text-white p-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold">{toCompany}</h1>
            <p className="text-sm">{myName}</p>
            <p className="text-sm">{myEmail}</p>
            <p className="text-sm">{myAddress}</p>
          </div>
          <div className="text-right">
            <p className="text-lg font-semibold">Invoice #{invoiceNumber}</p>
            <p className="text-sm">{date}</p>
          </div>
        </div>
      </div>

      {/* Payment Info */}
      <div className="p-6 bg-gray-50 grid grid-cols-2 gap-6 border-b">
        <div>
          <h3 className="text-gray-700 font-semibold">Payment Method</h3>
          <p className="text-gray-600">{paymentMethod}</p>
        </div>
        <div>
          <h3 className="text-gray-700 font-semibold">Payment Details</h3>
          <p className="text-gray-600">{paymentDetails}</p>
        </div>
      </div>

      {/* Tasks */}
      <div className="p-6">
        <table className="table-auto w-full text-left border-collapse">
          <thead>
            <tr style={{ backgroundColor: themeColor+'50' }}className="border-b">
              <th className="p-3 text-gray-600 font-medium">Task</th>
              <th className="p-3 text-gray-600 font-medium text-center">Hours</th>
              <th className="p-3 text-gray-600 font-medium text-right">Rate</th>
              <th className="p-3 text-gray-600 font-medium text-right">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {tasks.length > 0 ? (
              tasks.map((task, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="p-3">{task.itemName || "No Name"}</td>
                  <td className="p-3 text-center">{task.itemHours || 0}</td>
                  <td className="p-3 text-right">
                    {currency}
                    {task.itemRate}
                  </td>
                  <td className="p-3 text-right">
                    {currency}
                    {task.itemSubtotal}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="p-3 text-center text-gray-500">
                  No items added yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Total */}
      <div style={{ backgroundColor: themeColor }} className="p-6 text-white flex justify-end">
        <div className="text-right">
          <h2 className="text-lg font-bold">Total Due</h2>
          <p className="text-xl font-semibold">
            {currency}
            {totalDue || 0}
          </p>
        </div>
      </div>

      {/* Footer */}
      <div className="p-6 bg-gray-100 text-center text-sm text-gray-500">
        <p>Thank you for doing business with us!</p>
      </div>
    </div>
  );
};

export default SlickInvoiceTemplate;
