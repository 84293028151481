import React from "react";

const InvoiceTemplateMinimal = ({ data }) => {
  const {
    toCompany,
    myEmail,
    paymentDetails,
    myName,
    myAddress,
    currency,
    invoiceNumber,
    date,
    paymentMethod,
    sendTo,
    tasks,
    rate,
    totalDue,
    themeColor,
    themeFont
  } = data;

  return (
    <div style={{fontFamily: themeFont}} className="max-w-2xl mx-auto bg-white border border-gray-200 shadow-md rounded-md overflow-hidden">
      {/* Invoice Header */}
      <div style={{ backgroundColor: themeColor,  }} className={`text-white p-6`}>
        <h1 className="text-2xl font-bold">{toCompany}</h1>
        <p className="mt-1 text-sm">Invoice #: {invoiceNumber}</p>
        <p className="text-sm">Date: {date}</p>
      </div>

      {/* My Info Section */}
      <div className="p-6 bg-gray-50">
        <p className="font-semibold">{myName}</p>
        <p>{myAddress}</p>
        <p>{myEmail}</p>
      </div>

      {/* Payment Details */}
      <div className="grid grid-cols-2 gap-4 p-6 bg-gray-100 border-b">
        <div>
          <p className="text-sm text-gray-600">Payment Method</p>
          <p className="font-medium text-gray-800">{paymentMethod}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Send to</p>
          <p className="font-medium text-gray-800">{paymentDetails}</p>
        </div>
      </div>

      {/* Tasks Section */}
      <div className="p-6">
        <h2 className="text-lg font-bold text-gray-800">Tasks</h2>
        {tasks.length > 0 ? (
          <ul className="mt-4 space-y-4">
            {tasks.map((task, index) => (
              <li
                key={index}
                className="flex justify-between items-center bg-gray-50 p-4 rounded-lg shadow-sm"
              >
                <div>
                  <p className="text-gray-800">{task.itemName || "N/A"}</p>
                  <p className="text-sm text-gray-500">
                    Hours: {task.itemHours || 0}
                  </p>
                </div>
                <div>
                  <p className="text-gray-800">
                    {currency}{task.itemRate} <span className="text-sm text-gray-500">/hr</span>
                  </p>
                  <p className="text-lg font-bold">
                    {currency}{task.itemSubtotal}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center text-gray-500 mt-6">No items added.</p>
        )}
      </div>

      {/* Total Section */}
      <div className="p-6 bg-gray-50 border-t">
        <div className="flex justify-between items-center text-gray-800">
          <p className="text-lg font-semibold">Total Due</p>
          <p className="text-xl font-bold">{currency}{totalDue || 0}</p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTemplateMinimal;
