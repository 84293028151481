import React, { useState } from "react";

const ColorSelector = ({ onColorChange }) => {
  const [color, setColor] = useState("#2287be");

  const handleColorChange = (newColor) => {
    setColor(newColor);
    onColorChange(newColor);
  };

  return (
    <div className="flex flex-row items-start mt-2">
      {/* Color Picker */}
      <div className="flex flex-col gap-[1px]">
        <label htmlFor="colorPicker" className="text-xs font-semibold text-slate-500">
          Theme Color
        </label>
        <div className="flex flex-row">
        <input
          id="colorPicker"
          type="color"
          value={color}
          onChange={(e) => handleColorChange(e.target.value)}
          className="border border-gray-300 rounded-md cursor-pointer"
        />
        <input
          id="hexInput"
          type="text"
          value={color}
          onChange={(e) => {
            const newColor = e.target.value;
              handleColorChange(newColor);
    
          }}
          className="p-2 border border-gray-300 rounded-md w-32 focus:ring-blue-500 focus:border-blue-500"
        />
        </div>
      </div>

    </div>
  );
};

export default ColorSelector;

