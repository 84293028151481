import React, { useState } from "react";
import InvoiceTemplate1 from "./InvoiceTemplate1";
import InvoiceTemplate2 from "./InvoiceTemplate2";
import InvoiceTemplate3 from "./InvoiceTemplate3";
import InvoiceTemplate4 from "./InvoiceTemplate4";
import InvoiceTemplate5 from "./InvoiceTemplate5";
import InvoiceTemplate6 from "./InvoiceTemplate6";
import ColorSelector from "./ColorSelector";
import FontSelector from "./FontSelector";
import html2pdf from "html2pdf.js";

const App = () => {
  const [formData, setFormData] = useState({
    myName: "John Doe",
    myEmail: "john@doe.com",
    myAddress: "Example Address, 41th street",
    toCompany: "APPLESSED LTD COMPANY",
    invoiceNumber: "9212",
    date: '12 Jan 2025',
    paymentMethod: "Bank Transfer",
    paymentDetails: "IBAN: 4124123412",
    currency: "$",
    tasks: [
      {
        itemName: 'Finish the frontend for John.doe site',
        itemHours: 24,
        itemSubtotal: 600,
        itemRate: 25
      }
    ],
    rate: 25,
    totalDue: 600,
    themeColor: '#2287be',
    themeFont: 'Arial'
  });

  const [taskData, setTaskData] = useState({ itemName: "", itemHours: 0, itemSubtotal: 0 });
  const [selectedTemplate, setSelectedTemplate] = useState(1); // Track selected template

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTaskChange = (e) => {
    const { name, value } = e.target;
    setTaskData({ ...taskData, [name]: value });
  };

  const addTask = () => {
    const { itemName, itemHours } = taskData;
    const itemRate = formData.rate
    const itemSubtotal = itemHours * itemRate;
    const updatedTasks = [...formData.tasks, { itemName, itemHours, itemSubtotal, itemRate }];
    const totalDue = updatedTasks.reduce((total, task) => total + task.itemSubtotal, 0);
    setFormData({ ...formData, tasks: updatedTasks, totalDue });
    setTaskData({ itemName: "", itemHours: 0, itemSubtotal: 0 });
  };

  const removeTask = (index) => {
    const updatedTasks = formData.tasks.filter((_, i) => i !== index);
    const totalDue = updatedTasks.reduce((total, task) => total + task.itemSubtotal, 0);
    setFormData({ ...formData, tasks: updatedTasks, totalDue });
  };

  const generatePDF = () => {
    const element = document.getElementById("invoice");
    const options = {
      margin: 0.5,
      filename: `invoice-${formData.invoiceNumber}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(options).save();
  };

  const renderTemplate = () => {
    switch (selectedTemplate) {
      case 1:
        return <InvoiceTemplate1 data={formData} />;
      case 2:
        return <InvoiceTemplate2 data={formData} />;
      case 3:
        return <InvoiceTemplate3 data={formData} />;
      case 4:
        return <InvoiceTemplate4 data={formData} />;
      case 5:
        return <InvoiceTemplate5 data={formData} />;
      case 6:
        return <InvoiceTemplate6 data={formData} />
      default:
        return <InvoiceTemplate1 data={formData} />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-4 px-3 text-sm">
      <h1 className="flex justify-center items-center gap-2 text-lg font-bold text-center mb-4">
       <img width={50} src="logo512.png"/>{/* Render the SVG directly */} 
       <span>FastInvoice.online</span>
      </h1>

      <div className="bg-white shadow-sm rounded-lg p-4 max-w-4xl mx-auto text-xs">
        {/* From Info */}
        <h2 className="text-sm font-semibold mb-2">From Info</h2>
        <div className="sm:flex block gap-5 ">
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Your Name</label>
            <input
              type="text"
              name="myName"
              value={formData.myName}
              onChange={handleInputChange}
              placeholder="Your Name"
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Your Email</label>
            <input
              type="email"
              name="myEmail"
              value={formData.myEmail}
              onChange={handleInputChange}
              placeholder="Your Email"
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Your Address</label>
            <input
              type="text"
              name="myAddress"
              value={formData.myAddress}
              onChange={handleInputChange}
              placeholder="Your Address"
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
        </div>

        <div className="sm:flex block gap-5 ">
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Payment Method</label>
            <input
              type="text"
              name="paymentMethod"
              value={formData.paymentMethod}
              onChange={handleInputChange}
              placeholder="Bank Transfer"
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Payment Details</label>
            <input
              type="text"
              name="paymentDetails"
              value={formData.paymentDetails}
              onChange={handleInputChange}
              placeholder="IBAN: 1234561"
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
        </div>

        {/* To Info */}
        <hr className="my-2" />
        <h2 className="text-sm font-semibold">To Info</h2>
        <div className="sm:flex block gap-5 ">
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Company Name</label>
            <input
              type="text"
              name="toCompany"
              value={formData.toCompany}
              onChange={handleInputChange}
              placeholder="Company Name"
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Invoice Number</label>
            <input
              type="text"
              name="invoiceNumber"
              value={formData.invoiceNumber}
              onChange={handleInputChange}
              placeholder="Invoice Number"
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Date</label>
            <input
              type="text"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
        </div>

        <div className="sm:flex block gap-5 mt-1">
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Rate</label>
            <input
              type="text"
              name="rate"
              value={formData.rate}
              onChange={handleInputChange}
              placeholder="Hourly Rate"
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Currency</label>
            <input
              type="text"
              name="currency"
              value={formData.currency}
              onChange={handleInputChange}
              placeholder="$, USD, EUR..."
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
        </div>

        <hr className="my-2" />

        {/* Task Management */}
        <hr className="my-2" />
        <h2 className="text-sm font-semibold">Items Info</h2>
        <div className="sm:flex block gap-5 ">
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Item Name</label>
            <input
              type="text"
              name="itemName"
              value={taskData.itemName}
              onChange={handleTaskChange}
              placeholder="Item Name"
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
          <div className="flex flex-col gap-[1px]">
            <label className="text-xs font-semibold text-slate-500">Hours</label>
            <input
              type="number"
              name="itemHours"
              value={taskData.itemHours}
              onChange={handleTaskChange}
              placeholder="Hours"
              className="p-1 border border-[#585858] rounded-sm focus:ring-[#434643] focus:border-[#434643]"
            />
          </div>
          <button
            onClick={addTask}
            className="bg-[#585858] hover:bg-[#434643] text-white px-4 py-2 rounded-sm  transition mt-auto"
          >
            Add Item
          </button>
        </div>

        {/* Task List */}
        {formData.tasks.length > 0 && (
          <ul className="mt-4 list-disc">
            {formData.tasks.map((task, index) => (
              <li key={index} className="flex my-1 gap-2 bg-slate-200 p-2 rounded-lg justify-start items-center">
                <span>
                  {task.itemName} - {task.itemHours} hrs (${task.itemSubtotal.toFixed(2)})
                </span>
                <button
                  onClick={() => removeTask(index)}
                  className="text-red-500 hover:underline"
                >
                  x
                </button>
              </li>
            ))}
          </ul>
        )}

        {/* Template Selector */}
        <div className=" mt-4">
          <label className="block text-sm font-semibold mb-2">Choose Template</label>

          <div className="flex sm:flex-row flex-col justify-start gap-4">
            {[1, 2, 3, 4, 5, 6].map((template) => (
              <button
                key={template}
                onClick={() => setSelectedTemplate(template)}
                className={`px-4 py-2 rounded-md font-semibold ${
                  selectedTemplate === template
                    ? "bg-[#585858] text-white"
                    : "bg-gray-200 text-gray-800 hover:bg-[#434643] hover:text-white"
                }`}
              >
                Template {template}
              </button>
            ))}
          </div>
          <div className="flex flex-row gap-5">
            <ColorSelector onColorChange={(color) => setFormData({ ...formData, themeColor: color })} />
            <FontSelector onFontChange={(color) => setFormData({ ...formData, themeFont: color })} />
          </div>
        </div>


        {/* PDF Generation */}
        <div className="mt-4 text-center">
          <button
            onClick={generatePDF}
            className="bg-[#585858] hover:bg-[#434643] text-white px-4 py-2 rounded-sm transition"
          >
            Export as PDF
          </button>
        </div>
      </div>
      
      <div className="text-center mt-2">Preview</div>
      {/* Hidden invoice for PDF generation */}
      <div id="invoice" className="mt-6 ">
        {renderTemplate()}
      </div>
    </div>
  );
};

export default App;
