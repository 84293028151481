import React, { useState } from "react";

const InvoiceTemplateCorporate = ({ data }) => {
  const {
    toCompany,
    myEmail,
    paymentDetails,
    myName,
    myAddress,
    currency,
    invoiceNumber,
    date,
    paymentMethod,
    tasks,
    rate,
    totalDue,
    themeColor,
    themeFont
  } = data;

  const [msg, setmsg] = useState(`Thank you for your business with us!`)
  return (
    <div style={{fontFamily: themeFont}} className="max-w-3xl mx-auto bg-white shadow-lg rounded-md">
      {/* Header */}
      <div style={{ backgroundColor: themeColor }} className={`text-white px-8 py-6`}>
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">{toCompany}</h1>
          <div className="text-sm">
            <p>Invoice #: <span className="font-medium">{invoiceNumber}</span></p>
            <p>Date: <span className="font-medium">{date}</span></p>
          </div>
        </div>
      </div>

      {/* Sender and Recipient Details */}
      <div className="px-8 py-6 border-b">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h2 className="text-lg font-semibold text-gray-800">From</h2>
            <p className="text-gray-600">{myName}</p>
            <p className="text-gray-600">{myAddress}</p>
            <p className="text-gray-600">{myEmail}</p>
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-800">Payment Details</h2>
            <p className="text-gray-600">Method: {paymentMethod}</p>
            <p className="text-gray-600">{paymentDetails}</p>
          </div>
        </div>
      </div>

      {/* Tasks Table */}
      <div className="px-8 py-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">Invoice Items</h2>
        <table className="w-full border-collapse border border-gray-200">
          <thead>
            <tr className="bg-gray-100 border-b border-gray-200">
              <th className="text-left px-4 py-3 font-medium text-gray-600">Item</th>
              <th className="text-center px-4 py-3 font-medium text-gray-600">Hours</th>
              <th className="text-right px-4 py-3 font-medium text-gray-600">Rate</th>
              <th className="text-right px-4 py-3 font-medium text-gray-600">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {tasks.length > 0 ? (
              tasks.map((task, index) => (
                <tr key={index} className="border-b border-gray-200">
                  <td className="px-4 py-3">{task.itemName || "N/A"}</td>
                  <td className="px-4 py-3 text-center">{task.itemHours || 0}</td>
                  <td className="px-4 py-3 text-right">
                    {currency}{task.itemRate}
                  </td>
                  <td className="px-4 py-3 text-right">
                    {currency}{task.itemSubtotal}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center text-gray-500 py-6">
                  No items added.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Total Section */}
      <div className="px-8 py-6 bg-gray-50 border-t">
        <div className="flex justify-between items-center text-gray-800">
          <h2 className="text-lg font-bold">Total Due</h2>
          <p className="text-xl font-bold">{currency}{totalDue || 0}</p>
        </div>
      </div>

      {/* Footer */}
      <div style={{ backgroundColor: themeColor }} className={`py-4 text-white text-sm text-center`}>
        <p>Thank you for your business with us!</p>
      </div>
    </div>
  );
};

export default InvoiceTemplateCorporate;
