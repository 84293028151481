import React from "react";

const InvoiceTemplate = ({ data }) => { // Default themeColor is blue if not provided
  const {
    toCompany,
    myName,
    myEmail,
    myAddress,
    invoiceNumber,
    date,
    paymentMethod,
    paymentDetails,
    currency,
    tasks,
    rate,
    totalDue,
    themeColor,
    themeFont
  } = data;

  return (
    <div style={{fontFamily: themeFont}} className="max-w-3xl mx-auto bg-gray-50 rounded-lg shadow-lg p-10 font-serif">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-10">
        <div>
          <h1 style={{ color: themeColor }} className={`text-2xl font-extrabold`}>{toCompany}</h1>
          <p className="text-xl text-gray-700">{myName}</p>
          <p className="text-lg text-gray-600">{myEmail}</p>
          <p className="text-lg text-gray-600">{myAddress}</p>
        </div>
        <div className="text-right">
          <div className="text-xl text-gray-800 font-semibold">Invoice # {invoiceNumber}</div>
          <p className="text-lg text-gray-600">{date}</p>
        </div>
      </div>

      {/* Payment Info Section */}
      <div className="grid grid-cols-2 gap-12 mb-10 border-b pb-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-800">Payment Method</h2>
          <p className="text-lg text-gray-600">{paymentMethod}</p>
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray-800">Payment Details</h2>
          <p className="text-lg text-gray-600">{paymentDetails}</p>
        </div>
      </div>

      {/* Tasks Section */}
      <div className="overflow-x-auto mb-8">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr style={{ backgroundColor: themeColor }}className={`text-white text-lg`}>
              <th className="py-3 px-6 text-left">Task</th>
              <th className="py-3 px-6 text-center">Hours</th>
              <th className="py-3 px-6 text-left">Rate</th>
              <th className="py-3 px-6 text-right">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {tasks.length > 0 ? (
              tasks.map((task, index) => (
                <tr key={index} className="border-b hover:bg-gray-100">
                  <td className="py-4 px-6 text-sm">{task.itemName || "No Name"}</td>
                  <td className="py-4 px-6 text-center text-sm">{task.itemHours || 0}</td>
                  <td className="py-4 px-6 text-sm">{currency}{task.itemRate}</td>
                  <td className="py-4 px-6 text-right text-sm">{currency}{task.itemSubtotal}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="py-4 px-6 text-center text-gray-500">No items added yet</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Total Due Section */}
      <div className="flex justify-end items-center text-2xl font-bold text-gray-800">
        <p>Total Due: {currency}{totalDue || 0}</p>
      </div>

      {/* Footer Section */}
      <div className="text-center mt-8 text-sm text-gray-500">
        <p>Thank you for choosing our services. We look forward to working with you again!</p>
      </div>
    </div>
  );
};

export default InvoiceTemplate;
