import React, { useState } from "react";

const FontSelector = ({ onFontChange }) => {
  const [font, setFont] = useState("Arial");

  const fonts = [
    "Arial",
    "Georgia",
    "Helvetica",
    "Courier New",
    "Times New Roman",
    "Verdana",
    "Tahoma",
    "Trebuchet MS",
  ];

  const handleFontChange = (newFont) => {
    setFont(newFont);
    onFontChange(newFont);
  };

  return (
    <div className="flex flex-col items-start mt-2">
      <label htmlFor="fontPicker" className="text-xs font-semibold text-slate-500 ">
        Font Style
      </label>
      <select
        id="fontPicker"
        value={font}
        onChange={(e) => handleFontChange(e.target.value)}
        className="p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
      >
        {fonts.map((fontOption) => (
          <option key={fontOption} value={fontOption} style={{ fontFamily: fontOption }}>
            {fontOption}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FontSelector;
