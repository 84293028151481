import React from "react";

const InvoiceTemplateAlt = ({ data }) => {
  const {
    toCompany,
    myEmail,
    paymentDetails,
    myName,
    myAddress,
    currency,
    invoiceNumber,
    date,
    paymentMethod,
    sendTo,
    tasks,
    rate,
    totalDue,
    themeColor,
    themeFont,
  } = data;

  return (
    <div style={{ backgroundColor: themeColor, fontFamily: themeFont }} className={`max-w-3xl mx-auto text-white rounded-lg p-8 shadow-lg`}>
      {/* Header Section */}
      <div className="flex flex-col items-center border-b border-gray-700 pb-6">
        <h1 className="text-3xl font-bold">{toCompany}</h1>
        <div className="mt-2 text-sm">
          <p>Invoice #: <span className="font-medium">{invoiceNumber}</span></p>
          <p>Created: <span className="font-medium">{date}</span></p>
        </div>
      </div>

      {/* My Info Section */}
      <div className="mt-8 text-center">
        <p className="font-semibold">{myName}</p>
        <p>{myAddress}</p>
        <p>{myEmail}</p>
      </div>

      {/* Payment Details Section */}
      <div style={{ backgroundColor: themeColor }}className="mt-8 rounded-md p-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-400">Payment Method:</p>
            <p className="font-medium">{paymentMethod}</p>
          </div>
          <div className="text-right">
            <p className="text-gray-400">Send to:</p>
            <p className="font-medium">{paymentDetails}</p>
          </div>
        </div>
      </div>

      {/* Tasks Table */}
      <div className="mt-8">
        <table className="w-full border-collapse">
          <thead>
            <tr style={{ backgroundColor: themeColor }}>
              <th className="font-bold text-left px-4 py-3">Item</th>
              <th className="font-bold text-center px-4 py-3">Hours</th>
              <th className="font-bold text-left px-4 py-3">Rate</th>
              <th className="font-bold text-right px-4 py-3">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {tasks.length > 0 ? (
              tasks.map((task, index) => (
                <tr key={index} className="border-b border-gray-800">
                  <td className="px-4 py-3">{task.itemName || "N/A"}</td>
                  <td className="px-4 py-3 text-center">{task.itemHours || 0}</td>
                  <td className="px-4 py-3">{currency}{task.itemRate}</td>
                  <td className="px-4 py-3 text-right">{currency}{task.itemSubtotal}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center text-gray-500 py-6">
                  No items added.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Total Section */}
      <div className="mt-8 text-right">
        <div className="text-xl font-bold">
          Total Due: <span>{currency}{totalDue || 0}</span>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTemplateAlt;
